import axios from 'axios';
import configs from './../configs/config';

/**
 * props: email, password
 */
const doLogin=(props)=>{
   return axios.post(`${configs.URL_API_BASE}/login`, props);
}

export {doLogin}





