import React, { Component, Fragment } from 'react'

import NavLeft from "../utils/NavLeft";
import Header from "../utils/Header";
import Footer from "../utils/Footer";
import TableReservations from './TableReservations';
import { validateToken } from './../../session/SessionController';
import { getReservations } from './../../api_controller/reservations';
import moment from 'moment';
import Modal from "react-modal";
import ReservationModalContent from './ReservationModalContent';

export default class index extends Component {
    constructor() {
        super();
        validateToken();
        this.state = {
            data: [],
            modalIsOpen: false,
            record:null,
        }
        this.getData();
    }
    showReservation=(data)=>{
        console.log("record",data);
        this.setState({modalIsOpen:true,record:data});
    }
    closeModal = () => {
        this.setState({ modalIsOpen: false })
    }
    getData = () => {
        getReservations().then(res => {
            if (res.data.status === 200) {
                let auxData = res.data.results;
                auxData.forEach((item) => {
                    if (item.trip_type === 'One Way') {
                        item.arrival_datetime = moment(item.arrival_datetime).format('LLL');
                        item.departure_datetime = '';
                    } else {
                        item.arrival_datetime = moment(item.arrival_datetime).format('LLL');
                        item.departure_datetime = moment(item.departure_datetime).format('LLL');
                    }
                    item.id=item.reservation_id;
                })
                this.setState({ data: auxData });
            }
        }).catch(err => {

        });
    }
    render() {
        return (
            <Fragment>
                {/* //////////// LOADING
                <div className="loading"></div>
                {/* //////////// END LOADING */}
                <div className="air__layout air__layout--hasSider">

                    <NavLeft></NavLeft>

                    {/* //////////// CONTENT RIGHT */}
                    <div className="air__layout">
                        {/* /////// HEADER */}
                        <Header></Header>
                        {/* /////// END HEADER */}
                        {/* /////// MAIN CONTENT */}
                        <div className="air__layout__content p-5">
                            <div className="air__utils__content">
                                <div className="air__utils__heading">
                                    <h2>DASHBOARD</h2>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header card-header-flex">
                                                <div className="d-flex flex-column justify-content-center mr-auto">
                                                    <h5 className="mb-0">Reservations</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <TableReservations key={1585} data={this.state.data} showReservation={this.showReservation} ></TableReservations>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /////// END MAIN CONTENT */}
                        <Modal
                            appElement={document.getElementById("root")}
                            isOpen={this.state.modalIsOpen}
                            onRequestClose={this.closeModal}
                            className="ReservationModal"
                            contentLabel="Crear Amenidad">
                            <div className="modal-dialog modal-lg">
                                <ReservationModalContent record={this.state.record} closeModal={this.closeModal}></ReservationModalContent>
                            </div>
                        </Modal>
                        {/* /////// FOOTER */}
                        <Footer></Footer>
                        {/* /////// END FOOTER */}
                    </div>
                    {/* //////////// END CONTENT RIGHT */}
                </div>
            </Fragment>
        )
    }
}
