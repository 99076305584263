import React, { Component } from 'react'

export default class ReservationModalContent extends Component {
    render() {
        return (
            <div className="modal-content">
                <div className="modal-header">
                    <div>
                        <h5>Reservation Code:</h5>
                        <h3>ARCABO-{this.props.record.reservation_id}</h3>
                    </div>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        autoFocus={true}
                        aria-label="Close"
                        onClick={this.props.closeModal}>
                        <span aria-hidden="true">
                            &times;
                        </span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row border-bottom pb-1 mb-3">
                        <div className="col-12 col-md-6">
                            <h5>Payment Code: </h5>
                            <h4>{this.props.record.payment_code}</h4>
                            <h5>Estatus: </h5>
                            <h4>{this.props.record.paid_id}</h4>
                            <h5>Price:</h5>
                            <h4>${this.props.record.amount} USD - {this.props.record.trip_type}</h4>
                        </div>
                        <div className="col-12 col-md-6 cell">
                            <img className="img-fluid" src={this.props.record.image} alt={"transportation vehicle"} />
                        </div>
                    </div>
                    <div className="row border-bottom transport-section">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-md-6 align-self-middle">
                                    <h5>Transpor Information:</h5>
                                    <p>Transport:
                                      <br /><b>{this.props.record.transport_name}</b><br />
                                        Destination Zone: <br /><b> {this.props.record.zone_name}</b><br />
                                        Hotel Destination: <br /><b> {this.props.record.destination}</b></p>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h5>Traveler Information</h5>
                                    <p>Traveler Name: <b>{this.props.record.fullname}</b><br />
                                        Phone: <b> {this.props.record.phone}</b><br />
                                        Email: <b> {this.props.record.email}</b></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <h5> Arrival Details:</h5>
                                    <p>Arrival Date: <b>{this.props.record.arrival_datetime}</b><br />
                                        Arrival Airline:<b> {this.props.record.arrival_airline}</b><br />
                                        Arrival Flight: <b> {this.props.record.arrival_flight}</b></p>
                                </div>
                                {
                                    (this.props.record.trip_type == 'Round Trip') ? 
                                    <div className="col-6">
                                        <h5> Departure Details:</h5>
                                        <p>Departure Date: <b>{this.props.record.departure_datetime}</b><br />
                                            Departure Airline: <b> {this.props.record.departure_airline}</b><br />
                                            Departure Flight: <b> {this.props.record.departure_flight}</b></p>
                                    </div> : null
                                }
                                
                                <div className="col-12">
                                    <h5><i className="fas fa-pen-alt"></i> Observations</h5>
                                    <p><b>{this.props.record.observations}</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
