import React, { Component, Fragment } from 'react'
import { doLogin } from './../../api_controller/auth'

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: null,
            email: '',
            password: '',
            isSignedUp: false,
        };
    }
    componentDidMount = () => {
        const localStorage = window.localStorage;
        if (localStorage.getItem('apitoken_booking')) {
            this.redirectToDashboard();
        }
    }
    handleChangeInput = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    redirectToDashboard = () => {
        window.location.href = '/dashboard';
    };
    handleSubmit = (event) => {
        event.preventDefault();
        doLogin({ email: this.state.email, password: this.state.password }).then(res => {
            if (res.data.token != null) {
                this.setState({
                    isSignedUp: 'ok'
                });
                const localStorage = window.localStorage;
                localStorage.setItem('apitoken_booking', res.data.token);
                this.redirectToDashboard();
            } else {
                this.setState({
                    status: 'fail'
                });
            }
        });
    }

    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-md-7 col-lg-5 ">
                            <div className="pt-5 pb-5 mt-auto d-flex justify-content-center">
                                <img width="300" height="86" src="https://aroundcabo.s3-us-west-2.amazonaws.com/booking/logo.png" className="logo" />
                            </div>
                            <div className="air__auth__container pl-5 pr-5 pt-5 pb-5 bg-white text-center">
                                {
                                    this.state.status === 'fail' ? <div className="alert alert-danger" role="alert">
                                        acceso incorrecto.</div>
                                        : <div></div>
                                }
                                <div className="text-dark font-size-30 mb-4">Inicia Sesión</div>
                                <form onSubmit={this.handleSubmit} className="mb-4">
                                    <div className="form-group mb-4">
                                        <input type="email" value={this.state.email} onChange={this.handleChangeInput} name="email" className="form-control" placeholder="Email Address" />
                                    </div>
                                    <div className="form-group mb-4">
                                        <input type="password" value={this.state.password} onChange={this.handleChangeInput} name="password" className="form-control" placeholder="Password" />
                                    </div>
                                    <button className="text-center btn bg-accent-color-2 w-100 font-weight-bold font-size-18">
                                        ENTRAR
                                    </button>
                                </form>
                                {/*
                                <a href="recuperar-contrasena.html" className="text-secondary font-weight-bold font-size-18">Contraseña Olvidada?</a>
                                */}
                            </div>
                            <div className="text-gray-4 text-center mt-3">© 2020 Around Cabo Transportation. All rights reserved.</div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
