import axios from 'axios';
import configs from './../configs/config'

const validateToken = () => {

    const localStorage = window.localStorage;

   
    if (!localStorage.getItem('apitoken_booking') ) {
        window.location.href = '/';
    } else {

        axios.defaults.headers.common['Authorization'] = localStorage.getItem('apitoken_booking');
        axios.get(`${configs.URL_API_BASE}/verifytoken`,  {})
            .then(res => {
                if (res.data.auth == null || res.data.auth === false)  {
                    localStorage.removeItem('apitoken_booking');
                    window.location.href = '/';
                }
            }).catch((e)=>{
                console.log("error",e);
                localStorage.removeItem('apitoken_booking');
                window.location.href = '/';
            });

    }

}

const handlelogout = ()=>{
    localStorage.removeItem('apitoken_booking');
    window.location.href = '/';
}


export {validateToken,handlelogout};