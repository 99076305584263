import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';

import Dashboard from './components/dashboard';
import Login from './components/login';

function App() {
    return (
        <Router>
            <div className="admin air__menu--gray">
                <Route path="/dashboard" component={Dashboard} />
            </div>
            <div className="login">
                <Route path="/" exact component={Login} />
            </div>
        </Router>
    );
}
export default App; 
