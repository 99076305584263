import React, { Component, Fragment } from 'react';
import $ from  'jquery';

export default class NavLeft extends Component {

    pressDesk = () =>{
        $('body').toggleClass('air__menu--toggled');
    }

    pressMobile = ()=>{
        $('body').toggleClass('air__menu--mobileToggled');
    }

    render() {
        return (
            <Fragment>
                {/* //////////// MENU LEFT */}
                <div className="air__menuLeft">
                    <div className="air__menuLeft__outer">
                        <div className="air__menuLeft__mobileToggleButton air__menuLeft__mobileActionToggle" onClick={this.pressMobile}>
                            <span></span>
                        </div>
                        <div className="air__menuLeft__toggleButton air__menuLeft__actionToggle" onClick={this.pressDesk}>
                            <span></span>
                            <span></span>
                        </div>
                        <a href="/dashboard" className="air__menuLeft__logo">
                            <div className="air__menuLeft__logo__name">
                                <img src="https://aroundcabo.s3-us-west-2.amazonaws.com/booking/logo.png" className="logo img-fluid" height={'auto'} />
                            </div>
                        </a>
                        <a href="/dashboard" className="air__menuLeft__user">
                            <div className="air__menuLeft__user__avatar">
                                <img src="https://aroundcabo.s3-us-west-2.amazonaws.com/booking/logo.png" width={'45'} height={'auto'} />
                            </div>
                            <div className="air__menuLeft__user__name">
                                Daniel Liras
                            </div>
                            <div className="air__menuLeft__user__role">
                                Administrador
                            </div>
                        </a>
                        <div className="air__menuLeft__container air__customScroll">
                            <ul className="air__menuLeft__list">
                                <li className="air__menuLeft__item">
                                    <a href="/dashboard" className="air__menuLeft__link">
                                        <i className="fa fa-list mr-2" aria-hidden="true"></i>
                                        <span>Reservaciones</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="air__menuLeft__backdrop air__menuLeft__mobileActionToggle"></div>
                {/* //////////// END MENU LEFT */}
            </Fragment>
        )
    }
}
