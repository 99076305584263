import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import Swal from 'sweetalert2';
import "font-awesome/css/font-awesome.min.css";

export default class TableReservations extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "reservation_id",
                text: "ID",
                className: "reservation_id",
                align: "left",
                sortable: true,
            },
            {
                key: "fullname",
                text: "Name",
                className: "fullname",
                align: "left",
                sortable: true,
            },
            {
                key: "transport_name",
                text: "Transport",
                className: "transport_name",
                align: "left",
                sortable: true,
            },
            {
                key: "trip_type",
                text: "Trip Type",
                className: "trip_type",
                align: "left",
                sortable: true,
            },
            {
                key: "location",
                text: "Pickup Location",
                className: "location",
                align: "left",
                sortable: true,
            },
            {
                key: "destination",
                text: "Destination",
                className: "destination",
                align: "left",
                sortable: true,
            },
            {
                key: "arrival_datetime",
                text: "Arrival",
                className: "arrival_datetime",
                align: "left",
                sortable: true,
            },
            {
                key: "departure_datetime",
                text: "Departure",
                className: "departure_datetime",
                align: "left",
                sortable: true,
            },
            {
                key: "paid_id",
                text: "Paid",
                className: "Paid_id",
                align: "left",
                sortable: true,
            },
            {
                key: "action",
                text: "Actions",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment key={record.reservation_id}>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.showRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-eye"></i>
                                
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 50,
            length_menu: [50, 100, 200],
            button: {excel: false},
            sort:{ column: "reservation_id", order: "desc" }
        }

        this.state = {
            records: this.props.data
        }
    }
    showRecord = (record) => {
        this.props.showReservation(record);
        //this.props.handleOpenEdit(record);
      
      }
      
    editRecord = (record) => {
        alert("edicion");
        //this.props.handleOpenEdit(record);
      
      }
  
      deleteRecord = (record) => {
          console.log("Delete Record", record);
          Swal.fire({
              title: '¿Esta seguro de eliminar el usuario seleccionado?',
              text: "Una vez eliminada no podrá ser recuperado.",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#1c666f',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Eliminar',
              cancelButtonText: 'Cancelar'
          }).then((result) => {
              if (result.value) {
                  //this.props.handledelete(record.id_user)
              }
          });
      }
      componentWillReceiveProps = (next_props) => {
          this.setState({records: next_props.data});
      }
  
    render() {
        return (
            <ReactDatatable
            className="table table-bordered table-striped table-responsive"
                    key={'table11'}
                    config={this.config}
                    records={this.state.records}
                    columns={this.columns}
            />
        )
    }
}
